import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import Icon from '@beewise/icon';
import constants from 'appConstants';
import { getIsHiveSelected, getIsHiveInspected, getHiveThermalScanData } from 'components/views/Bhome/selectors';
import { toggleSelectedHive, setHiveToInspect, setFrameToInspect } from 'components/views/BeeHome/actions';
import Card from 'components/reusables/Card';
import cx from 'classnames';
import Tooltip from '@beewise/tooltip';
import { getPendingMessages } from 'components/views/BeeHome/selectors';
import ThermalGraph from '../ThermalGraph';
import Frame from './StationFrame';
import BeeFrame from './BeeFrame';
import {
    getCommonFrameWidths,
    areFramesInBeeCountPendingMessages,
    areFramesInFeedingPendingMessages,
    areFramesInScanPendingMessages,
    getLatestScanTimestamp,
    isScanOlderThanTwoWeeks,
} from '../utils';
import { FRAME_OPTIONS } from '../constants';

import './SmallHive.scss';

const Title = ({ name, checkbox, feedingHive, beeCountHive, scanHive, tooltipText }) => (
    <div className="small-hive-header">
        <div className="small-hive-header-block">
            {checkbox}
            <Tooltip
                content={
                    <div className="small-hive-header-icons">
                        {beeCountHive && <Icon type="bordered-bee" className="small-hive-icon" />}
                        {scanHive && <Icon type="bordered-box" className="small-hive-icon" />}
                        {feedingHive && <Icon type="feed" className="small-hive-icon" />}
                    </div>
                }
                position="top center"
                hideOnScroll
                disabled={!beeCountHive && !scanHive && !feedingHive}
                className="small-hive-header-block-tooltip"
                isInverted
            >
                <h4 className="small-hive-name">
                    {name} {(beeCountHive || scanHive || feedingHive) && <span className="small-hive-name-dot" />}
                </h4>
            </Tooltip>
            {tooltipText && <div className="small-hive-header-tooltip">{tooltipText}</div>}
        </div>
    </div>
);

Title.propTypes = {
    name: PropTypes.string,
    checkbox: PropTypes.node,
    feedingHive: PropTypes.bool,
    beeCountHive: PropTypes.bool,
    scanHive: PropTypes.bool,
    tooltipText: PropTypes.string,
};
const getDynamicPartitionX = frames =>
    frames.find(frame => frame.type === constants.FRAME_TYPES.PARTITION)?.place?.position?.x;

const getIsFrameInsideHive = ({ frames, frame }) => {
    const partitionX = getDynamicPartitionX(frames);
    return frame.place?.position?.x > partitionX;
};

const Hive = ({ name, markedHives, hive, selectedFrameOptions, beeCountFrameShift }) => {
    const dispatch = useDispatch();
    const [tooltipText, setTooltipText] = useState('');
    const { from: hiveStart, to: hiveEnd, frames, id, station } = hive;
    const { hiveThermalScanData, isSelectMode, isInspected, isSelected, pendingMessages } = useSelector(
        state => ({
            hiveThermalScanData: getHiveThermalScanData(state, station, hiveStart, hiveEnd),
            isSelectMode: !!state.beehome.selectedHives.length,
            isInspected: getIsHiveInspected(state, id),
            isSelected: getIsHiveSelected(state, id),
            pendingMessages: getPendingMessages(state),
        }),
        shallowEqual
    );

    const feedingHive = areFramesInFeedingPendingMessages(hive, pendingMessages);
    const beeCountHive = areFramesInBeeCountPendingMessages(hive, pendingMessages);
    const scanHive = areFramesInScanPendingMessages(hive, pendingMessages);
    const latestScanTimestamp = getLatestScanTimestamp(frames);
    const scanOlderThanTwoWeeks = isScanOlderThanTwoWeeks(latestScanTimestamp);

    const handleSelectHives = e => {
        e.stopPropagation();
        dispatch(toggleSelectedHive(id));
    };

    const handleSetHiveToInspect = () => {
        dispatch(setFrameToInspect({}));
        dispatch(setHiveToInspect(id));
    };

    const handleFrameClick = frame => e => {
        e.stopPropagation();
        dispatch(setFrameToInspect({}));
        dispatch(setHiveToInspect(null));
        dispatch(setFrameToInspect(frame));
    };

    let commonWidth = [];

    if (selectedFrameOptions[FRAME_OPTIONS.BEES]) {
        commonWidth = getCommonFrameWidths({ frames, hiveStart, hiveEnd });
    }

    return (
        <Card
            onClick={handleSetHiveToInspect}
            isSelected={isSelected}
            className={cx('small-hive', { 'reduced-opacity': scanOlderThanTwoWeeks })}
            isInspected={isInspected}
            isCheckboxShown
            handleCheckboxClick={handleSelectHives}
            renderTitle={checkbox => (
                <Title
                    name={name}
                    checkbox={checkbox}
                    feedingHive={feedingHive}
                    beeCountHive={beeCountHive}
                    scanHive={scanHive}
                    tooltipText={tooltipText}
                />
            )}
            isSelectMode={isSelectMode}
        >
            <div className="small-hive-content">
                {selectedFrameOptions[FRAME_OPTIONS.BEES] &&
                    frames?.map((frame, i) => (
                        <BeeFrame
                            key={`${frame.id}-bee`}
                            frame={frame}
                            commonWidth={commonWidth[i]}
                            isInsideHive={getIsFrameInsideHive({ frames, frame })}
                            hiveStart={hiveStart}
                            hiveEnd={hiveEnd}
                            beeCountFrameShift={beeCountFrameShift}
                        />
                    ))}
                {frames?.map(frame => (
                    <Frame
                        key={frame.id}
                        frame={frame}
                        markedHives={markedHives}
                        hiveStart={hiveStart}
                        hiveEnd={hiveEnd}
                        onFrameClick={handleFrameClick(frame)}
                        isEmptyFrame={!selectedFrameOptions[FRAME_OPTIONS.FRAME_CONTENT]}
                        setTooltipText={setTooltipText}
                    />
                ))}
                {selectedFrameOptions[FRAME_OPTIONS.THERMAL] && !!hiveThermalScanData?.length && (
                    <ThermalGraph data={hiveThermalScanData} />
                )}
            </div>
        </Card>
    );
};

Hive.propTypes = {
    name: PropTypes.string,
    markedHives: PropTypes.arrayOf(PropTypes.shape()),
    hive: PropTypes.shape({
        from: PropTypes.number,
        to: PropTypes.number,
        frames: PropTypes.arrayOf(PropTypes.shape()),
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        station: PropTypes.string,
    }),
    selectedFrameOptions: PropTypes.shape(),
    beeCountFrameShift: PropTypes.number,
};

export default memo(Hive);
