import React, { useEffect, useState } from 'react';
import { loading } from '@beewise/react-utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchDashboardGridData } from 'components/views/Dashboard/actions';
import LeftPanel from './components/LeftPanel';
import { getCurrentBhome } from '../BeeHome/selectors';
import { FETCH_CURRENT_BHOME, FETCH_LAYOUT_SNAPSHOT, FETCH_HIVES } from '../BeeHome/actionTypes';
import { getIsLimitedViewCompany } from '../../../selectors';
import {
    fetchCurrentBhome,
    fetchFrames,
    fetchHives,
    fetchRanches,
    fetchYards,
    fetchPendingMessages,
    fetchHistoryMessages,
} from '../BeeHome/actions';
import { groupFramesByStationAndHives } from '../BeeHome/selectors/utils';
import { fetchCompanyLocations } from '../Dashboard/actions';
import BhomeView from './components/BhomeView';
import Map from './components/Map';
import RightPanelMap from './components/RightPanelMap';
import RightPanel from './components/RightPanel';

import './Bhome.scss';

const Bhome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1024);

    const bhomeId = +params.id;
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const isLimitedView = useSelector(getIsLimitedViewCompany);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        dispatch(
            fetchHives(data => {
                if (!data.bhomes?.length || bhomeId) {
                    return;
                }

                const bhomeToNavigate = isLimitedView ? data.bhomes.find(item => item.has_scan) : data.bhomes[0];
                navigate(`/bhome/${bhomeToNavigate.id}`);
            })
        );
        dispatch(fetchCompanyLocations());
        dispatch(fetchRanches());
        dispatch(fetchYards());
        dispatch(fetchDashboardGridData({ offset: 0, limit: 0, type: '' }));
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (!bhomeId) {
            return;
        }
        dispatch(
            fetchFrames(bhomeId, response => {
                const hives = groupFramesByStationAndHives(response.frames, response.stations);
                const areHivesEmpty = Object.values(hives).every(hive => !hive.length);

                if (areHivesEmpty) {
                    return;
                }

                dispatch(fetchPendingMessages({ bhomeId }));
                dispatch(fetchHistoryMessages(bhomeId));
            })
        );

        if (currentBhome.id !== bhomeId) {
            dispatch(fetchCurrentBhome(bhomeId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, bhomeId]);

    return (
        <main className="main main-bhome">
            <LeftPanel />
            <BhomeView />
            {isSmallScreen ? <RightPanelMap /> : <Map />}
            <RightPanel />
        </main>
    );
};

export default loading([FETCH_CURRENT_BHOME.default, FETCH_LAYOUT_SNAPSHOT.default, FETCH_HIVES.default])(Bhome);
