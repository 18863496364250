import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { faCalendarCheck, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import IconButton from '@beewise/icon-button';
import Hives from 'components/reusables/HivesV2';
import Actions from 'components/reusables/HivesV2/Actions';
import LastActionStatus from 'components/reusables/LastActionStatus';
import { setFrameToInspect, setHiveToInspect } from 'components/views/BeeHome/actions';
import { isBhomeFramesFetched } from 'components/reusables/Alerts/selectors';
import { toggleRightPanel } from 'actions';
import { getIsRightPanelShown } from 'selectors';
import { getCurrentBhome, getLastFeedDate } from '../../selectors';
import Footer from './components/Footer';
import { getBhomeDefinedHives, getPendingMessagesLength } from '../../../BeeHome/selectors';
import emptyState from './bh-empty-state-with-text.png';

import './BhomeView.scss';

const getName = (place, area) => (place && area ? `${place}, ${area}` : area || place);

const BhomeView = () => {
    const dispatch = useDispatch();
    const historySliderRef = useRef();
    const [isHistoryView, setIsHistoryView] = useState(false);
    const currentBhome = useSelector(getCurrentBhome, shallowEqual);
    const hives = useSelector(getBhomeDefinedHives, shallowEqual);
    const pendingMessagesLength = useSelector(getPendingMessagesLength);
    const lastFeed = useSelector(getLastFeedDate);
    const isFetched = useSelector(isBhomeFramesFetched);
    const isRightPanelShown = useSelector(getIsRightPanelShown);
    const areHivesEmpty = Object.values(hives).every(hive => !hive.length);

    const handleOpenActionPanel = useCallback(() => {
        dispatch(setFrameToInspect({}));
        dispatch(setHiveToInspect(null));
        dispatch(toggleRightPanel(true));
    }, [dispatch]);

    return (
        <section className="bhome">
            {isFetched && (
                <>
                    <div className="bhome-header-wrapper">
                        <div className="bhome-header">
                            <h3 className="large-title">BH {currentBhome.id}</h3>
                            <span className="subtitle">
                                {getName(currentBhome?.ranch_name || currentBhome?.yard_name, currentBhome.area)}
                            </span>
                            <IconButton
                                className={cn('notification-icon', {
                                    'is-notified': !!pendingMessagesLength,
                                    'is-open': isRightPanelShown,
                                })}
                                icon={faInfoCircle}
                                onClick={handleOpenActionPanel}
                                type="secondary"
                            />
                        </div>
                        <div className="bhome-header subheader">
                            <LastActionStatus date={lastFeed} icon="feed" name="Last feed" />
                            <LastActionStatus
                                date={currentBhome.lastVisitTime}
                                icon={faCalendarCheck}
                                name="Last visit"
                            />
                        </div>
                    </div>
                    {areHivesEmpty ? (
                        <img className="empty-state" src={emptyState} alt="Empty State" />
                    ) : (
                        <>
                            <Actions
                                currentBhome={currentBhome}
                                isHistoryView={isHistoryView}
                                historySliderRef={historySliderRef}
                            />
                            <Hives
                                setIsHistoryView={setIsHistoryView}
                                historySliderRef={historySliderRef}
                                handleOpenActionPanel={handleOpenActionPanel}
                            />
                        </>
                    )}
                    <Footer />
                </>
            )}
        </section>
    );
};

export default BhomeView;
