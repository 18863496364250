import constants from 'appConstants';
import { keyBy } from 'lodash-es';
import { ACTION_MESSAGES_TYPES, SUB_ACTION_TYPES } from './hooks/useActionHandlers/utils';

export const ACTION_TYPES = {
    FULL_SCAN: 'scan',
    COUNT_BEES: 'countBees',
};

export const FEED_ACTION_TYPE = 'FEED';

export const isFrameExistInHive = (hive, frameToCheck) => {
    const { from, to, station: hiveStation } = hive;
    const { x } = frameToCheck.place.position;
    const { station: frameStation } = frameToCheck.place;

    return x >= from && x <= to && hiveStation === frameStation;
};

export const areFramesInPendingMessages = (hive, pendingMessages, messageType) => {
    const totalFrames = hive.frames.length;
    const halfFrames = Math.ceil(totalFrames / 2);

    const matchedFramesCount = pendingMessages
        ?.filter(message => message.command === messageType)
        ?.reduce((count, message) => {
            const matchedFrames = message.payload.frames.filter(frame => isFrameExistInHive(hive, frame));
            return count + matchedFrames.length;
        }, 0);

    return matchedFramesCount >= halfFrames;
};
export const areFramesInFeedingPendingMessages = (hive, pendingMessages) =>
    pendingMessages
        ?.filter(message => message.command === constants.COMMANDS.FILL_FEEDER)
        ?.some(message => message.payload.frames.some(frame => isFrameExistInHive(hive, frame)));

export const areAllHivesHavingFeedActionInProgress = (hives, pendingMessages) =>
    Object.values(hives)
        .flat()
        .every(hive => areFramesInFeedingPendingMessages(hive, pendingMessages));

export const areFramesInBeeCountPendingMessages = (hive, pendingMessages) =>
    areFramesInPendingMessages(hive, pendingMessages, ACTION_TYPES.COUNT_BEES);

export const areAllHivesHavingBeeCountActionInProgress = (hives, pendingMessages) =>
    Object.values(hives)
        .flat()
        .every(hive => areFramesInBeeCountPendingMessages(hive, pendingMessages));

export const areFramesInScanPendingMessages = (hive, pendingMessages) =>
    areFramesInPendingMessages(hive, pendingMessages, ACTION_TYPES.FULL_SCAN);

export const areAllHivesHavingScanActionInProgress = (hives, pendingMessages) =>
    Object.values(hives)
        .flat()
        .every(hive => areFramesInScanPendingMessages(hive, pendingMessages));

export const areSelectedHivesHavingActionInProgress = (hives, selectedHives, pendingMessages, action) => {
    if (!selectedHives.length) return false;

    const allHives = keyBy([...hives[constants.STATIONS.A], ...hives[constants.STATIONS.B]], 'id');
    const filteredSelectedHives = selectedHives.map(selectedHiveId => allHives[selectedHiveId]).filter(Boolean);

    if (action === FEED_ACTION_TYPE) {
        return filteredSelectedHives.every(hive => areFramesInFeedingPendingMessages(hive, pendingMessages));
    } else if (action === ACTION_TYPES.COUNT_BEES) {
        return filteredSelectedHives.every(hive =>
            areFramesInPendingMessages(hive, pendingMessages, ACTION_MESSAGES_TYPES.COUNT_BEES)
        );
    } else if (action === ACTION_TYPES.FULL_SCAN) {
        return filteredSelectedHives.every(hive =>
            areFramesInPendingMessages(hive, pendingMessages, ACTION_MESSAGES_TYPES.FULL_SCAN)
        );
    }
};

export const getHivesWithoutPendingMessages = ({ hives, frameAction, pendingMessages }) =>
    hives.filter(hive => {
        if (frameAction === FEED_ACTION_TYPE) {
            return !areFramesInFeedingPendingMessages(hive, pendingMessages);
        } else if (frameAction === SUB_ACTION_TYPES.BEE_SCAN) {
            return !areFramesInPendingMessages(hive, pendingMessages, ACTION_MESSAGES_TYPES.COUNT_BEES);
        } else if (frameAction === SUB_ACTION_TYPES.FULL_SCAN) {
            return !areFramesInPendingMessages(hive, pendingMessages, ACTION_MESSAGES_TYPES.FULL_SCAN);
        } else {
            return false;
        }
    });

// frameShift is needed for beeCount mode as manual numerical correction
export const getFrameLeftPosition = ({ positionX, hiveStart, hiveEnd, frameShift = 0 }) =>
    ((positionX - hiveStart + frameShift) / (hiveEnd - hiveStart - constants.DEFAULT_FRAME_WIDTH.STATIC_PARTITION)) *
    100;

// all shifts where checked manually, idea is to center beeCount frame by regular frame
const SCREEN_TO_BEECOUNT_FRAME_SHIFT = {
    1920: 8,
    1440: 12,
    1024: 11,
    768: 11,
};

export const getCommonFrameWidths = ({ frames, hiveStart, hiveEnd }) => {
    const framesPositions = frames.map(frame => ({
        left: getFrameLeftPosition({ positionX: frame.place.position.x, hiveStart, hiveEnd }),
    }));

    const dynamicWidths = framesPositions.map((pos, i, arr) => {
        const leftDistance = i > 0 ? pos.left - arr[i - 1].left : pos.left;
        const rightDistance = i < arr.length - 1 ? arr[i + 1].left - pos.left : 100 - pos.left;

        return Math.min(leftDistance, rightDistance);
    });

    return dynamicWidths;
};

export const getFrameShift = width => {
    const sortedSizes = Object.keys(SCREEN_TO_BEECOUNT_FRAME_SHIFT)
        .map(Number)
        .sort((a, b) => b - a);

    const matchedSize = sortedSizes.find(size => width >= size);

    return matchedSize ? SCREEN_TO_BEECOUNT_FRAME_SHIFT[matchedSize] : 9;
};

export const getLatestScanTimestamp = frames =>
    frames.reduce((acc, frame) => {
        if (frame.type !== constants.FRAME_TYPES.COMB_FRAME) {
            return acc;
        }

        const timestamps = [
            frame?.sideA?.scanTimestamp,
            frame?.sideB?.scanTimestamp,
            frame?.sideA?.beeCount?.scanTimestamp,
            frame?.sideB?.beeCount?.scanTimestamp,
        ];

        timestamps.forEach(timestamp => {
            if (timestamp && (!acc || timestamp > acc)) {
                acc = timestamp;
            }
        });

        return acc;
    }, null);

export const isScanOlderThanTwoWeeks = latestScanTimestamp => {
    const twoWeeksInMilliseconds = 2 * 7 * 24 * 60 * 60 * 1000;
    const now = Date.now();

    return latestScanTimestamp && now - latestScanTimestamp >= twoWeeksInMilliseconds;
};

export const isFrameInsideHive = ({ markedHives, frame }) =>
    markedHives?.some(
        hive =>
            frame?.place?.station === hive?.station &&
            frame?.place?.position?.x >= hive?.from &&
            frame?.place?.position?.x < hive?.to
    );
