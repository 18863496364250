import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import constants from 'appConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { fetchInspectedFrameData } from 'components/views/BeeHome/actions';
import useGetFrameStationHiveAndFrameNumbers from 'utils/useGetFrameStationHiveAndFrameNumbers';
import { calculateFrameMetrics, getCombFrameImages } from 'components/reusables/RightPanel/components/HiveBlock/utils';
import useTransformedImagesData from 'components/reusables/RightPanel/components/HiveBlock/useTransformedImagesData';
import useLoadingEffect from 'components/views/Bhome/hooks/useLoadingEffect';
import { getInspectedFrame, getInspectedFrameData } from 'components/views/Bhome/selectors';
import ActionIcon from 'components/reusables/ActionIcon';
import PanelItem from '../components/PanelItem';
import FrameImageBlock from './components/FrameImageBlock';
import FullScreenImageModal from './components/FullScreenImageModal';
import { IMAGE_TYPES } from './utils';
import './FramePanel.scss';

export const fullScreenImageBaseDefault = {
    image: null,
    side: null,
    rootImages: null,
    index: 0,
};

const getNameWithPercent = percent => `${percent}%`;

const FramePanel = ({ onClose }) => {
    const dispatch = useDispatch();
    const inspectedFrame = useSelector(getInspectedFrame, shallowEqual);
    const inspectedData = useSelector(getInspectedFrameData, shallowEqual);

    const [isAnnotatedPhotosShown, setIsAnnotatedPhotosShown] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState(fullScreenImageBaseDefault);

    const isLoading = useLoadingEffect(inspectedFrame);
    const frameInfo = useGetFrameStationHiveAndFrameNumbers(inspectedFrame);

    const frameMetrics =
        inspectedFrame && calculateFrameMetrics({ sideA: inspectedFrame?.sideA, sideB: inspectedFrame?.sideB });

    const isCombFrame = inspectedFrame?.type === constants.FRAME_TYPES.COMB_FRAME;
    const { beeCountImages, frameImages: scanImages } = useTransformedImagesData(inspectedData?.latestData?.images);

    useEffect(() => {
        if (!isCombFrame || !inspectedFrame?.frameId) {
            return;
        }

        dispatch(fetchInspectedFrameData({ frameId: inspectedFrame.frameId }));
    }, [dispatch, inspectedFrame?.frameId, isCombFrame]);

    useEffect(() => {
        if (!isModalOpen || !inspectedData) {
            return;
        }

        setFullScreenImage(prevState => {
            const scansByType = fullScreenImage.type === IMAGE_TYPES.SCAN ? scanImages : beeCountImages;
            const image = getCombFrameImages(inspectedFrame, scansByType, prevState.side, isAnnotatedPhotosShown);

            return {
                ...prevState,
                image,
                rootImages: scansByType,
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inspectedData]);

    const handleFullScreenImageClose = () => {
        setIsModalOpen(false);
        setFullScreenImage(fullScreenImageBaseDefault);
    };

    const handleToggleShowAnnotatedPhotosOnPanel = () => setIsAnnotatedPhotosShown(!isAnnotatedPhotosShown);

    const handleToggleShowAnnotatedPhotos = () => {
        setIsAnnotatedPhotosShown(!isAnnotatedPhotosShown);

        const image = getCombFrameImages(
            inspectedFrame,
            fullScreenImage.rootImages,
            fullScreenImage.side,
            !isAnnotatedPhotosShown
        );

        if (image.url || image.resizedUrl) {
            setFullScreenImage(prevFullScreenImage => ({ ...prevFullScreenImage, image }));
        }
    };

    const handleFullScreenImageShow =
        (side, images, index = 0, type) =>
        () => {
            const image = getCombFrameImages(inspectedFrame, images, side, isAnnotatedPhotosShown);

            if (image.url || image.resizedUrl) {
                setFullScreenImage({ image, side, rootImages: images, type, index });
                setIsModalOpen(true);
            } else {
                handleFullScreenImageClose();
            }
        };

    return (
        <>
            <div className="right-panel-content frame-panel">
                {!isLoading && (
                    <>
                        <div className="frame-panel-header">
                            <div className="frame-panel-header-top">
                                <div className="frame-panel-header-top-left">
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        className="frame-panel-header-top-left-icon"
                                        onClick={onClose}
                                    />
                                    <div className="frame-panel-header-top-left-hive">{frameInfo?.title}</div>
                                </div>
                                <ActionIcon
                                    isActive={isAnnotatedPhotosShown}
                                    onClick={handleToggleShowAnnotatedPhotosOnPanel}
                                    icon="Ai"
                                    name="AI View"
                                />
                            </div>
                            <div className="frame-panel-header-bottom">
                                <div className="frame-panel-header-bottom-block">
                                    <PanelItem
                                        iconType="BeeSimple"
                                        label="Bee"
                                        data={getNameWithPercent(frameMetrics?.beesPercent ?? 0)}
                                    />
                                    <PanelItem
                                        iconType="Honey"
                                        label="Honey"
                                        data={getNameWithPercent(frameMetrics?.honeyPercent)}
                                    />
                                </div>
                                <div className="frame-panel-header-bottom-block">
                                    <PanelItem
                                        iconType="Brood"
                                        label="Brood"
                                        data={getNameWithPercent(frameMetrics?.broodPercent)}
                                    />
                                    <PanelItem
                                        iconType="Pollen"
                                        label="Pollen"
                                        data={getNameWithPercent(frameMetrics?.pollenPercent)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="frame-panel-main">
                            {isCombFrame && (scanImages?.sideA?.fileName || scanImages?.sideB?.fileName) && (
                                <FrameImageBlock
                                    title="Frame content"
                                    fullScreenImage={fullScreenImage}
                                    images={scanImages}
                                    handleFullScreenImageShow={handleFullScreenImageShow}
                                    inspectedFrame={inspectedFrame}
                                    showAnnotatedPhotos={isAnnotatedPhotosShown}
                                    type={IMAGE_TYPES.SCAN}
                                    startImageIndex={0}
                                />
                            )}
                            {isCombFrame && (beeCountImages?.sideA?.fileName || beeCountImages?.sideB?.fileName) && (
                                <FrameImageBlock
                                    title="Bee count"
                                    fullScreenImage={fullScreenImage}
                                    images={beeCountImages}
                                    handleFullScreenImageShow={handleFullScreenImageShow}
                                    inspectedFrame={inspectedFrame}
                                    showAnnotatedPhotos={isAnnotatedPhotosShown}
                                    type={IMAGE_TYPES.BEE_COUNT}
                                    startImageIndex={2}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
            {isModalOpen && (
                <FullScreenImageModal
                    onClose={handleFullScreenImageClose}
                    image={fullScreenImage.image}
                    title={frameInfo?.imageTitle}
                    handleToggleShowAnnotatedPhotos={handleToggleShowAnnotatedPhotos}
                    isAnnotatedPhotosShown={isAnnotatedPhotosShown}
                    setFullScreenImage={setFullScreenImage}
                    inspectedData={inspectedData}
                    inspectedFrame={inspectedFrame}
                />
            )}
        </>
    );
};

FramePanel.propTypes = {
    onClose: PropTypes.func,
};

export default FramePanel;
